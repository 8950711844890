import React from 'react';
import { useSelector } from 'react-redux';

import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { memberTabAdminActionDone } from '@wix/bi-logger-groups/v2';

import { Button } from 'wui/Button';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';

import { selectDialog, selectGroupStatus } from 'store/selectors';
import type { IJoinRequest } from 'store/groups/types';

import { useController } from 'common/context/controller';

import * as dataHooks from './dataHooks';

export function PrivateProfileDisclaimer() {
  const bi = useBi();
  const { t } = useTranslation();
  const { group$, application$ } = useController();

  const { isMobile } = useEnvironment();

  const dialog = useSelector(selectDialog('disclaimer'));
  const groupId = dialog.params?.groupId as string;
  const status = useSelector(selectGroupStatus(groupId));

  const isLoading = status.membership.updating || status.requirements.loading;

  return (
    <AlertDialog
      isOpen={dialog.isOpen}
      onClose={handleClose}
      paperProps={{ 'data-hook': dataHooks.PRIVATE_PROFILE_DISCLAIMER_DIALOG }}
    >
      <DialogTitle
        alert
        title={t('groups-web.private-profile-disclaimer.title')}
      />
      <DialogContent alert>
        <DialogContentText alert>
          {t('groups-web.private-profile-disclaimer.subtitle')}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button
          outlined
          variant="basic"
          onClick={handleCancel}
          data-hook="cancel button"
          fullWidth={isMobile}
        >
          {t('groups-web.cancel')}
        </Button>
        <Button
          variant="basic"
          disabled={isLoading}
          onClick={handleSubmit}
          data-hook="ok button"
          loading={isLoading}
          fullWidth={isMobile}
        >
          {t('groups-web.private-profile-disclaimer.ok')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );

  function handleSubmit() {
    reportClick('confirm');
    application$.shareProfileConsent(groupId, true);
    group$.join({
      ...(dialog.params as IJoinRequest),
      agreedToShareProfile: true,
    });
  }

  function handleCancel() {
    reportClick('cancel');
    application$.shareProfileConsent(groupId, false);
    application$.closeDialog('disclaimer');
  }

  function handleClose() {
    reportClick('close');
    application$.shareProfileConsent(groupId, false);
    application$.closeDialog('disclaimer');
  }

  function reportClick(action: string) {
    bi.report(
      memberTabAdminActionDone({
        action,
        origin: 'private_profile_in_groups',
      }),
    );
  }
}

PrivateProfileDisclaimer.displayName = 'PrivateProfileDisclaimer';
